import axios from 'axios'
import qs from 'qs';
axios.defaults.baseURL = process.env.NODE_ENV === 'production'?'/':'http://127.0.0.100'
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// Add a request interceptor
axios.interceptors.request.use(config => {
    if (config.method.toLowerCase() === 'post') {
        if(typeof config.headers['Content-Type']=="undefined"){
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = qs.stringify(config.data, {arrayFormat: 'brackets'})
        }
    }
    return config
})
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
export default axios;
