import { createApp,h } from 'vue'
import App from './App.vue'
import router from './router'






const app = createApp({
    render: ()=>h(App),
    mounted () {
        // You'll need this for renderAfterDocumentEvent.
        lintOnSave: process.env.NODE_ENV === 'development'  ||   document.dispatchEvent(new Event('render-event'))
    },
})
// import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/lib/theme-chalk/display.css';
import {ElRow ,ElCol,ElButton,ElInput,ElNotification,ElMessage}  from 'element-plus';
app.component(ElRow.name, ElRow);
app.component(ElCol.name, ElCol);
app.component(ElButton.name, ElButton);
app.component(ElInput.name, ElInput);
app.component(ElMessage.name, ElMessage);
// import Home from './views/layout/home/home.vue'
// app.component('Home',Home)
// app.use(ElementPlus)
app.use(router)
app.use(ElNotification)
import axios from '@/utils/axios'
import VueAxios from 'vue-axios'

app.use(VueAxios, axios)
app.mount('#app')


