<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  overflow: hidden;
}

#app {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  position: absolute;
}
</style>
