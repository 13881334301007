

import {createRouter,createWebHashHistory} from 'vue-router'
const _import =  file => () => import('@/views/' + file + '.vue')



const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes: [
        {path: '/', redirect: '/home',meta:{affix:true}},
        {path: '/home/:fouc?', component: _import('layout/home/home')},
        {path: '/user', component: _import('user/user')},
    ]

})

export default router